import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { ENV_CONFIG } from "../constants";
import { getUserDetails } from "./browserStorage";
import { getComponentTexts } from "./commonMethods";

//Sentry helps in logging app errors.
export const initializeSentry = () => {
  Sentry.init({
    dsn: ENV_CONFIG.sentryApiKey,
    integrations: [new Integrations.BrowserTracing()],

    tracesSampleRate: 1.0,
  });
};

export const catchApiErrors = (error, payload) => {
  const sentryMessages = getComponentTexts("sentry");
  const user = getUserDetails();
  const payloadStringify = JSON.stringify(payload);

  Sentry.configureScope((scope) =>
    scope
      .setLevel(sentryMessages.api_error_title)
      .setUser({ userId: user ? user.id : sentryMessages.unauth_error_message })
      .setExtra("response", payloadStringify)
  );
  // Sentry.showReportDialog(); - If you want get users feedback on error
  return Sentry.captureException(error);
};
